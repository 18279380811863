<template lang="html">
  <div id="contact-page">

    <section class="placed-backg banner-section">
      <div class="container">
        <div class="row">
          <!-- <div class="col-lg-6"></div> -->

          <div class="col-sm-7 offset-sm-5 col-md-6 offset-md-6 col-text">
            <h1 class="title">CONTACTO</h1>

            <p class="p-lg">
              Estamos a un click de distancia. Contáctanos para atenderte de manera personal.
            </p>
          </div>
        </div>
      </div>
    </section>

    <section class="locations-row-section">
      <div class="container">
        <div class="row">
          <div class="col-12 col-locations">
            <span>GUADALAJARA</span>
            <span>LEÓN</span>
            <span>MOLERIA</span>
          </div>
        </div>
      </div>
    </section>

    <section class="matriz-section">
      <div class="container">
        <div class="row">
          <div class="col-lg-5 col-xl-6 col-map">
            <div class="box-map">
              <div class="map">
                <GmapMap
                  :center="{lat: 20.6941354, lng: -103.3776443 }"
                  :zoom="14.9">
                  <GmapMarker
                    :position="{ lat: 20.6941354, lng: -103.3776443 }"
                    :clickable="false"
                    :draggable="false"
                  />
                </GmapMap>
              </div>
            </div>
          </div>

          <div class="col-lg-7 col-xl-6 col-info">
            <div class="box">
              <div class="col-sm col-logo">
                <img src="public/images/pages/contact/matriz.svg">
              </div>

              <div class="col-sm col-text">
                <h5>VISÍTANOS EN:</h5>
                <p>Calle 12 de Diciembre 2909</p>
                <p>Col. Jardines de Plaza del Sol</p>
                <p>Guadalajara, Jalisco. C.P. 44510</p>
              </div>
            </div>
          </div>
        </div>

      </div>
    </section>

    <section class="container locations-section">
      <div class="row">
        <div class="col-md-6 col-location" v-for="(l, lInx) in locations" :key="'lInx-'+lInx">
          <div class="box">
            <h4 class="name">{{ l.name }}</h4>

            <div class="box-descr">
              <p v-html="l.descr">
              </p>
            </div>

            <a class="btn btn-s1 bg-c-primary" href="#">ATENCIÓN EN {{ l.city }} <i class="fab fa-whatsapp icon"></i></a>

            <div class="box-map">
              <GmapMap
                :center="{lat: 20.6941354, lng: -103.3776443 }"
                :zoom="14.9">
                <GmapMarker
                  :position="{ lat: 20.6941354, lng: -103.3776443 }"
                  :clickable="false"
                  :draggable="false"
                />
              </GmapMap>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="projects-section">
      <div class="box">
        <h2 class="title">PROYECTOS Y COLABORACIONES</h2>
        <p>
          <span>expansion@viajare.mx</span>
          <span class="sep">|</span>
          <span>coordinacionmkt@viajare.mx</span>
        </p>
      </div>
    </section>

    <section class="container networks-section">
      <div class="row align-items-stretch">
        <div class="col-md-7 col-info">
          <div class="box">
            <div class="inside">
              <p>
                <img class="logo" src="public/images/logo.svg">
              </p>
              <h5>Escríbenos en nuestros <strong>redes sociales</strong></h5>
            </div>
          </div>
        </div>

        <div class="col-md-5 col-icons">
          <div class="box">
            <div class="inside">
              <p class="mb-3">
                <a class="t-250 btn-network xl" href="#"><i class="fa-brands fa-whatsapp"></i></a>
              </p>

              <p>
                <a class="t-250 btn-network" href="#"><i class="fa-brands fa-facebook-f"></i></a>
                <a class="t-250 btn-network" href="#"><i class="fa-brands fa-tiktok"></i></a>
                <a class="t-250 btn-network" href="#"><i class="fa-brands fa-instagram"></i></a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>

  </div>
</template>

<script>
export default {
  data() {
    return {
      locations: [
        { name: 'LEÓN',
          city: 'LEÓN',
          descr: `Blvd Juan Alonso de Torres #1909<br />
          Valle del Campestre<br />
          Col. Valle del Campestre, C.P. 44510<br />
          León, Guanajuato`
        },
        { name: 'ONIX',
          city: 'GUADALAJARA',
          descr: `Sucursal Onix<br />
          Calle Onix #1903<br />
          Col. San Marcos, C.P. 44330<br />
          Guadalajara, Jalisco`
        },
        { name: 'MORELIA',
          city: 'MORELIA',
          descr: `Chavinda: Morelia<br />
          Calle Galeana #9<br />
          Col. San Pedro y San Pablo, C.P. 59580<br />
          Chavinda, Michoacán`
        },
        { name: 'ZAPOPAN',
          city: 'ZAPOPAN',
          descr: `Plaza Navona, Planta Alta, Local #25<br />
          Av. Sta. Margarita #4860 Poniente<br />
          Jardín Real, C.P. 45136<br />
          Zapopan, Jalisco`
        },
      ]
    }
  }
}
</script>
