import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter);

//Componentes
//import Login from './components/admin/Login.vue';

const page="./components/page/";

const MyRouter = new VueRouter({
  	routes:[
	    { path: '/', component: require(page+'home.vue').default },
      { path: '/quiero-viajar', component: require(page+'travel/index.vue').default, meta:{ title:"Quiero viajar" }},
      { path: '/travelover', component: require(page+'travelover/index.vue').default, meta:{ title:"Travelover" }},
      { path: '/facilidades-de-pago', component: require(page+'payments/index.vue').default, meta:{ title:"Facilidades de pago" }},
      { path: '/agentes', component: require(page+'agents/index.vue').default, meta:{ title:"Agentes travelovers autorizados" }},
      { path: '/contacto', component: require(page+'contact/index.vue').default, meta:{ title:"Contacto" }},
      { path: '/aviso-de-privacidad', component: require(page+'text-pages/aviso.vue').default, meta:{ title:"Aviso de privacidad" }},
      { path: '/terminos-y-condiciones', component: require(page+'text-pages/terminos.vue').default, meta:{ title:"Términos y condiciones" }},
      { path: '/preguntas-frecuentes', component: require(page+'text-pages/preguntas.vue').default, meta:{ title:"Preguntas frecuentes" }},

      { path: '/blog', component: require(page+'blog/index.vue').default, meta:{ title:"Blog" }},
	    { path: '/blog/:id', component: require(page+'blog/detail.vue').default, meta:{ title:"Blog" }},

	    // { path: '/checkout', component: require(page+'checkout.vue').default, meta:{title:"Checkout"}},
	  ]
});

MyRouter.beforeEach((to, from, next) => {
	window.scrollTo(0,0);
	if(window.app.__vue__ && window.app.__vue__.$refs.loadingBar){
		window.app.__vue__.$refs.loadingBar.start();
	}
	next();
});

MyRouter.afterEach((to, from) => {

	if(window.app.__vue__ && window.app.__vue__.$refs.loadingBar){
		setTimeout(()=>{
			window.app.__vue__.$refs.loadingBar.done();
		},500);
	}


});

//Titulos del website
import VueDocumentTitlePlugin from "vue-document-title-plugin";
Vue.use(VueDocumentTitlePlugin, MyRouter,
	{ defTitle: "Viajare", filter: (title)=>{ return title+" - Viajare"; } }
);

// export {routes};
export default MyRouter;
