<template lang="html">
  <div id="travel-page">

    <section class="placed-backg header-banners-section-s1" v-bind:style="{ backgroundImage: 'url(public/images/pages/travel/banner-1.jpg)' }">
      <div class="container">
        <div class="row">
          <div class="col-12">
            <div class="box">
              <h1>QUIERO VIAJAR</h1>

              <p class="p-lg">
                Entérate en todo momento de las noticias más relevantes del mundo del turismo.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="container offers-section">
      <h2 class="text-center title-s1 c-primary">PAQUETES Y OFERTAS</h2>
      <h4 class="subtitle">NACIONALES E INTERNACIONALES</h4>

      <div class="box-options">
        <a class="btn btn-s2 outline-cprimary b-radius btn-sm active">PLAYA</a>
        <a class="btn btn-s2 outline-cprimary b-radius btn-sm">AVENTURA</a>
        <a class="btn btn-s2 outline-cprimary b-radius btn-sm">ARTE Y CULTURA</a>
        <a class="btn btn-s2 outline-cprimary b-radius btn-sm">POPULARES</a>
        <a class="btn btn-s2 outline-cprimary b-radius btn-sm">LUXURY</a>
        <a class="btn btn-s2 outline-cprimary b-radius btn-sm">OFERTAS ESPECIALES</a>
        <a class="btn btn-s2 outline-cprimary b-radius btn-sm">TODO INCLUIDO</a>
      </div>

      <div class="box-carousels">
        <swiper class="swiper" :options="offersOptions">
          <swiper-slide v-for="(o, oInx) in offers" :key="'oInx-'+oInx">
            <router-link class="placed-backg box-offer" v-bind:style="{ backgroundImage: 'url('+o.imageURL+')' }" to="">
              <div class="b-top">
                <div class="inside">
                  <h4 class="text-1">{{ o.title }}</h4>
                  <h5 class="text-2">OFERTAS ESPECIALES</h5>
                  <h6 class="text-3">{{ o.extra }}</h6>
                </div>
              </div>

              <div class="b-bottom">
                <span class="btn btn-s2 bg-c-primary">MÁS INFO</span>
              </div>
            </router-link>
          </swiper-slide>

          <div class="swiper-button-prev" slot="button-prev"></div>
          <div class="swiper-button-next" slot="button-next"></div>
        </swiper>
      </div>
    </section>

  </div>
</template>

<script>
export default {
  data() {
    return {
      offers: [
        { imageURL: 'public/images/pages/travel/offer-1.jpg', title: 'TODO INCLUIDO', extra: '*VALIDO HASTA FEB 17 2024' },
        { imageURL: 'public/images/pages/travel/offer-2.jpg', title: '2 X 1', extra: '*SOLO PARA ADULTOS' },
        { imageURL: 'public/images/pages/travel/offer-3.jpg', title: 'TODO INCLUIDO', extra: '*PARA TODA LA FAMILIA' },
        { imageURL: 'public/images/pages/travel/offer-4.jpg', title: '2 X 1', extra: '*SOLO PARA ADULTOS' },
      ],

      // == Carousels options ==
      offersOptions: {
        loop: true,
        slidesPerView: 2,
        spaceBetween: 30,
        centeredSlides: true,

        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        },

        breakpoints: {
          992: {
            slidesPerView: 2,
            spaceBetween: 30
          },
          1: {
            slidesPerView: 1,
            spaceBetween: 20
          },
        }
      }
      // == ==
    }
  }
}
</script>
