<template lang="html">
  <div id="agents-page">

    <section class="placed-backg header-banners-section-s1" v-bind:style="{ backgroundImage: 'url(public/images/pages/agents/banner-1.jpg)' }">
      <div class="container">
        <div class="row">
          <div class="col-12">
            <div class="box w-55-p text-left">
              <h1>AGENTES TRAVELOVERS AUTORIZADOS</h1>

              <p class="p-lg">
                Nuestros travelovers son agentes de viajes que te ayudarán a cumplir el viaje de tus sueños.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="agents-section">
      <div class="placed-backg flex-center-xy box-banner">
        <div class="inside">
          <p>
            <img class="vive" src="public/images/pages/agents/vive.png">
          </p>
          <h5>MÁS DE 100 EXPERTOS EN VIAJES<br />NACIONALES E INTERNACIONALES</h5>
        </div>
      </div>

      <div class="container box-agents">
        <div class="row">
          <div class="col-12 col-title">
            <p class="p-stars">
              <i class="fas fa-star"></i>
              <i class="fas fa-star"></i>
              <i class="fas fa-star"></i>
              <i class="fas fa-star"></i>
              <i class="fas fa-star"></i>
            </p>
            <h2 class="title-s1">ASESORES VIAJARE</h2>
            <h5 class="sub">Busca la página de tu asesor aqui:</h5>
          </div>
        </div>

        <div class="row">
          <div class="col-md-6 col-xl-4 col-agents" v-for="(a, aInx) in agents">
            <div class="box-agent">
              <div class="image">
                <img class="placed-backg" src="public/images/shared/empty.png" v-bind:style="{ backgroundImage: 'url('+a.imageUrl+')' }">
              </div>

              <div class="description">
                <h5 class="name">{{ a.name }}</h5>
                <h6 class="location ">{{ a.phone }}</h6>

                <p>
                  <a class="btn btn-s1 bg-c-primary" @click="openModal(a)">CONTACTAR</a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <!-- Modal info de agente -->
    <b-modal modal-class="modal-agent-s1" ref="modal-agent" size="lg" title="" centered hide-footer no-close-on-backdrop no-close-on-esc>
      <div class="row no-gutters">
        <div class="col-lg-7 col-info">
          <h6 class="t-1">TRAVELOVER</h6>
          <h4 class="name">{{ modal.name }}</h4>
          <h5 class="location">Tel. {{ modal.phone }}</h5>

          <div class="box-networks">
            <a class="t-150 btn-network" v-if="modal.phone" :href="'tel:'+modal.phone"><i class="far fa-phone"></i></a>
            <a class="t-150 btn-network" v-if="modal.email" :href="'mailto:'+modal.phone"><i class="far fa-envelope"></i></a>
            <a class="t-150 btn-network" v-if="modal.page" target="_blank" :href="modal.page"><i class="fab fa-facebook-f"></i></a>
          </div>

          <div class="box-description">
            <!-- <p>
              ¡Hola! ¿Listo para que preparemos tu viaje? ¡Perfecto! Dime cómo puedo ayudarte. Vamos resolviendo tus dudas y viendo algunas opciones para que tú decidas cómo quieres agendar ese viaje que tienes en mente. Tengo más de 7 años de experiencia asesorando a las personas que como tú, quieren vivir la increíble experiencia que es viajar a través de VIAJARE.
            </p> -->
            <h5 class="f-w-600">Agentes travelovers autorizados</h5>
            <p>
              Nuestros travelovers son agentes de viajes que te ayudarán a cumplir el viaje de tus sueños.
            </p>
            <p class="mt-3">
              Somos más de 100 expertos en viajes nacionales e internacionales.
            </p>
          </div>

          <div class="box-buttons">
            <a class="btn btn-s1 bg-c-primary" :href="'tel:'+modal.phone">CONTACTAR</a>
          </div>

          <p class="mt-4 pt-2 text-center">
            <!-- <a class="link-rate" @click="$refs['modal-rate'].show()"><i class="fas fa-stars"></i> Calificar Agente</a> -->
          </p>
        </div>

        <div class="col-lg-5 placed-backg col-image" v-bind:style="{ backgroundImage: 'url('+modal.imageUrlLg+')' }">
          <img src="public/images/pages/agents/empty.png">

          <div class="box-stars">
            <b-form-rating inline value="5" disabled></b-form-rating>
            <span class="num">5/5</span>

            <span class="btn-comments" @click="$refs['modal-comments'].show();"><i class="fas fa-comments"></i></span>
          </div>
        </div>
      </div>
    </b-modal>
    <!--  -->

    <!-- Modal calificar -->
    <b-modal modal-class="modal-agent-s1" ref="modal-rate" size="" title="" centered hide-footer no-close-on-backdrop no-close-on-esc>
      <div class="row no-gutters">
        <b-form class="col-lg-12 text-center col-info" @submit="onSubmitRate">
          <div class="box-rate-form">
            <h6 class="t-1">CALIFICAR AGENTE</h6>

            <div class="b-photo">
              <div class="placed-backg photo" v-bind:style="{ backgroundImage: 'url(public/images/pages/agents/agent-6.jpg)' }"></div>
            </div>

            <h4 class="mt-1 name">DIANA JOCELYN GUERRERO</h4>

            <p class="mt-3 txt-gray">
              Por favor califica a nuestro agente.
            </p>

            <div class="text-center">
              <div class="b-stars">
                <b-form-rating required inline v-model="forRating.stars"></b-form-rating>
              </div>
            </div>

            <b-form-group class="mt-4 custom-form-group-s1">
              <b-form-input
                v-model="forRating.name"
                placeholder="Nombre"
                required
              ></b-form-input>
            </b-form-group>

            <b-form-group class="mt-4 custom-form-group-s1">
              <b-form-textarea
                v-model="forRating.comment"
                placeholder="Comentario"
                rows="5"
                max-rows="5"
                required
              ></b-form-textarea>
            </b-form-group>

            <p class="mt-3">
              <b-button type="submit" class="w-100 btn-s2 outline-cprimary btn-sm">Calificar</b-button>
            </p>
          </div>
        </b-form>
      </div>
    </b-modal>
    <!--  -->

    <!-- Modal comentarios -->
    <b-modal modal-class="modal-agent-s1" ref="modal-comments" size="" title="" centered hide-footer no-close-on-backdrop no-close-on-esc>
      <div class="row no-gutters">
        <div class="col-lg-12 text-center col-info">
          <div class="box-rate-form">
            <h6 class="t-1">AGENTE</h6>

            <div class="b-photo">
              <div class="placed-backg photo" v-bind:style="{ backgroundImage: 'url(public/images/pages/agents/profile-2-lg.jpg)' }"></div>
            </div>

            <h4 class="mt-1 name">DIANA JOCELYN GUERRERO</h4>

            <div class="text-center">
              <div class="b-stars">
                <b-form-rating required inline v-model="starsExample" readonly></b-form-rating>
              </div>
            </div>

            <div class="box-comments">
              <h5 class="box-title">COMENTARIOS</h5>

              <div class="box-comment" v-for="x in 6">
                <h6 class="co-date">10/Jun/2024</h6>
                <h6 class="co-name">Jose Antonio Sanchez</h6>

                <p>
                  Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor serunt mollit anim id est laborum.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </b-modal>
    <!--  -->

  </div>
</template>

<script>
export default {
  data() {
    return {
      agents: [
        { imageUrl: 'public/images/pages/agents/profile-2.jpg', imageUrlLg: 'public/images/pages/agents/profile-2-lg.jpg', name: 'Ana Cristina  Corona  Marquez', phone: '33 3479 2732', email: 'coronamarcristina@gmail.com', page: 'https://www.facebook.com/profile.php?id=100065478489194' },
        { imageUrl: 'public/images/pages/agents/profile-2.jpg', imageUrlLg: 'public/images/pages/agents/profile-2-lg.jpg', name: 'Carol Padilla', phone: '33 3115 6606', email: 'caroldepadilla.viajare@gmail.com', page: 'https://www.facebook.com/carolypepepadillaagenteviajare' },
        { imageUrl: 'public/images/pages/agents/profile-2.jpg', imageUrlLg: 'public/images/pages/agents/profile-2-lg.jpg', name: 'Diana Jocelyn Guerrero Bugarin', phone: '33 2184 2036', email: 'dianaguerreroviajare@gmail.com', page: 'https://www.facebook.com/DianaGuerreroBugarinAgenteViajare' },
        { imageUrl: 'public/images/pages/agents/profile-2.jpg', imageUrlLg: 'public/images/pages/agents/profile-2-lg.jpg', name: 'Diana Lorena Lopez', phone: '33 1895 4568', email: 'dleon7657@gmail.com', page: null },
        { imageUrl: 'public/images/pages/agents/profile-2.jpg', imageUrlLg: 'public/images/pages/agents/profile-2-lg.jpg', name: 'Karla Vianey Orozco Dueñas', phone: '33 1760 5434', email: 'karlaorozcod@gmail.com', page: null },
        { imageUrl: 'public/images/pages/agents/profile-2.jpg', imageUrlLg: 'public/images/pages/agents/profile-2-lg.jpg', name: 'Maricela Barajas Urea', phone: '33 1362 7199', email: 'lore_barajas5@yahoo.com.mx', page: 'https://www.facebook.com/MaricelaBarajasAgenteViajare' },
        { imageUrl: 'public/images/pages/agents/profile-2.jpg', imageUrlLg: 'public/images/pages/agents/profile-2-lg.jpg', name: 'Marisol Contreras Fuentes', phone: '33 1705 8606', email: 'contreras.marisol1908@gmail.com', page: 'https://www.facebook.com/MarisolContrerasAgenteViajare' },
        { imageUrl: 'public/images/pages/agents/profile-2.jpg', imageUrlLg: 'public/images/pages/agents/profile-2-lg.jpg', name: 'Mayra Paulina Rubio Castro', phone: '38 4115 3574', email: 'paulina1890110@gmail.com', page: 'https://www.facebook.com/profile.php?id=100064929015959' },
        { imageUrl: 'public/images/pages/agents/profile-1.jpg', imageUrlLg: 'public/images/pages/agents/profile-1-lg.jpg', name: 'Miguel Campos Navarro', email: 'micana96@hotmail.com', phone: '35 1127 3410', page: 'https://www.facebook.com/Viajarechavinda/' },
        { imageUrl: 'public/images/pages/agents/profile-2.jpg', imageUrlLg: 'public/images/pages/agents/profile-2-lg.jpg', name: 'Nayeli Carmona Solis', email: 'namarysolis@gmail.com', phone: '6634060044', page: null },
        { imageUrl: 'public/images/pages/agents/profile-2.jpg', imageUrlLg: 'public/images/pages/agents/profile-2-lg.jpg', name: 'Viviana Rosas Perez', email: 'afiliaciones1@camaradecomerciogdl.mx', phone: '33 2187 2918', page: 'https://www.facebook.com/profile.php?id=61553150622450' },
        { imageUrl: 'public/images/pages/agents/profile-2.jpg', imageUrlLg: 'public/images/pages/agents/profile-2-lg.jpg', name: 'Zhayra Iliana Cadena  Herrera', email: 'zhacahe@hotmail.com', phone: '33 1324 7813', page: 'https://www.facebook.com/profile.php?id=100070552312387' },
        { imageUrl: 'public/images/pages/agents/profile-2.jpg', imageUrlLg: 'public/images/pages/agents/profile-2-lg.jpg', name: 'Ana Paula O Henry  Vazquez', email: 'anapauov87@gmail.com', phone: '33 1216 2816', page: 'https://www.facebook.com/profile.php?id=100070544312621' },
        { imageUrl: 'public/images/pages/agents/profile-2.jpg', imageUrlLg: 'public/images/pages/agents/profile-2-lg.jpg', name: 'Carmen Guadalupe  Garcia Orozco', email: 'lupistravelrealty@gmail.com', phone: '33 2537 9817', page: 'https://www.facebook.com/CarmenPilaAgenteViajare' },
        { imageUrl: 'public/images/pages/agents/profile-2.jpg', imageUrlLg: 'public/images/pages/agents/profile-2-lg.jpg', name: 'Claudia Ivette Becerra Sandoval', email: 'claudiabecerra.viajare@gmail.com', phone: '33 2423 9000', page: 'https://www.facebook.com/profile.php?id=100063698764714' },
        { imageUrl: 'public/images/pages/agents/profile-1.jpg', imageUrlLg: 'public/images/pages/agents/profile-1-lg.jpg', name: 'Francisco Chavez Salazar', email: 'jfrancisco.chavezs@gmail.com', phone: '39 5102 6706', page: 'https://www.facebook.com/Tudestinoviajare' },
        { imageUrl: 'public/images/pages/agents/profile-1.jpg', imageUrlLg: 'public/images/pages/agents/profile-1-lg.jpg', name: 'Jorge Antonio Ramïñrez Alvarez', email: 'lic.jorgermz@hotmail.com', phone: '33 3967 5916', page: null },
        { imageUrl: 'public/images/pages/agents/profile-1.jpg', imageUrlLg: 'public/images/pages/agents/profile-1-lg.jpg', name: 'Jose Martin Martinez  Alvarado', email: 'jmma.890105@gmail.com', phone: '33 2054 0190', page: 'https://www.facebook.com/ViajareTequila/' },
        { imageUrl: 'public/images/pages/agents/profile-2.jpg', imageUrlLg: 'public/images/pages/agents/profile-2-lg.jpg', name: 'Karen Andrea Garibay  Ruelas', email: 'karengaribay12@gmail.com', phone: '33 3173 9116', page: 'https://www.facebook.com/KarenGaribayAgenteViajare' },
        { imageUrl: 'public/images/pages/agents/profile-2.jpg', imageUrlLg: 'public/images/pages/agents/profile-2-lg.jpg', name: 'Lizeth Sarahí  Ruiz Mederos', email: 'lizethmedeross@gmail.com', phone: '33 1756 6158', page: 'https://www.facebook.com/LizethMederosAgenteViajare' },
        { imageUrl: 'public/images/pages/agents/profile-2.jpg', imageUrlLg: 'public/images/pages/agents/profile-2-lg.jpg', name: 'Monica Noemi Monta‘o Garcia', email: 'monismontano@gmail.com', phone: '33 1071 7522', page: 'https://www.facebook.com/profile.php?id=100067183021165' },
        { imageUrl: 'public/images/pages/agents/profile-1.jpg', imageUrlLg: 'public/images/pages/agents/profile-1-lg.jpg', name: 'Roberto Cortez', email: 'cortezroberto_@hotmail.com', phone: '33 3662 6511', page: 'https://www.facebook.com/RobertoCortezAgenteviajare' },
        { imageUrl: 'public/images/pages/agents/profile-1.jpg', imageUrlLg: 'public/images/pages/agents/profile-1-lg.jpg', name: 'Sergio Alejandro Velazquez Diaz', email: 'serchvelazquez@hotmail.com', phone: '33 5331 0060', page: 'https://www.facebook.com/profile.php?id=61557979427447' },
        { imageUrl: 'public/images/pages/agents/profile-2.jpg', imageUrlLg: 'public/images/pages/agents/profile-2-lg.jpg', name: 'Alejandra Del Carmen Landeros Alvarez', email: 'alejandralanderosalvarez@hotmail.com', phone: '33 2757 4379', page: 'https://www.facebook.com/AlejandraLanderosAgenteViajare' },
        { imageUrl: 'public/images/pages/agents/profile-2.jpg', imageUrlLg: 'public/images/pages/agents/profile-2-lg.jpg', name: 'Anahi Lopez Mendez', email: 'valentinalopez.viajare@gmail.com', phone: '35 2113 1525', page: null },
        { imageUrl: 'public/images/pages/agents/profile-2.jpg', imageUrlLg: 'public/images/pages/agents/profile-2-lg.jpg', name: 'Brenda Lisset Grajeda  Valadez', email: 'brendagrajedava@gmail.com', phone: '37 5124 6967', page: 'https://www.facebook.com/profile.php?id=61555965201837' },
        { imageUrl: 'public/images/pages/agents/profile-2.jpg', imageUrlLg: 'public/images/pages/agents/profile-2-lg.jpg', name: 'Carolina  Segura  Silva', email: 'karosegura1997@gmail.com', phone: '33 2619 1263', page: 'https://www.facebook.com/search/top?q=carolina%20segura%20agente%20viajare' },
        { imageUrl: 'public/images/pages/agents/profile-1.jpg', imageUrlLg: 'public/images/pages/agents/profile-1-lg.jpg', name: 'Cesar Josue Castillo Gonzalez', email: 'cesarjosuec155@gmail.com', phone: '33 2491 0837', page: null },
        { imageUrl: 'public/images/pages/agents/profile-2.jpg', imageUrlLg: 'public/images/pages/agents/profile-2-lg.jpg', name: 'Cynthia Vazquez Rodriguez', email: 'cynthia_030597@hotmail.com', phone: '33 2286 0672', page: 'https://www.facebook.com/profile.php?id=61563094140310' },
        { imageUrl: 'public/images/pages/agents/profile-2.jpg', imageUrlLg: 'public/images/pages/agents/profile-2-lg.jpg', name: 'Dulce Andrea Aviles Azpeitia', email: 'avadul.21@gmail.com', phone: '33 3337 2960', page: 'https://www.facebook.com/profile.php?id=61560349143076' },
        { imageUrl: 'public/images/pages/agents/profile-2.jpg', imageUrlLg: 'public/images/pages/agents/profile-2-lg.jpg', name: 'Griselda Chavolla Rios', email: 'gchavolla.viajare@gmail.com', phone: '55 1818 3813', page: 'https://www.facebook.com/search/top?q=griselda%20chavolla%20agente%20viajare' },
        { imageUrl: 'public/images/pages/agents/profile-2.jpg', imageUrlLg: 'public/images/pages/agents/profile-2-lg.jpg', name: 'Irma De La Mora Mendez', email: 'idelamora@yahoo.com.mx', phone: '33 1088 7660', page: null },
        { imageUrl: 'public/images/pages/agents/profile-1.jpg', imageUrlLg: 'public/images/pages/agents/profile-1-lg.jpg', name: 'Jordan Steve Maldonado Hernández', email: 'Jordanviajare@outlook.com', phone: '33 2832 9840', page: null },
        { imageUrl: 'public/images/pages/agents/profile-1.jpg', imageUrlLg: 'public/images/pages/agents/profile-1-lg.jpg', name: 'Leonardo Erick Ramirez Hernandez', email: 'drecheldy@gmail.com', phone: '33 3559 0500', page: null },
        { imageUrl: 'public/images/pages/agents/profile-2.jpg', imageUrlLg: 'public/images/pages/agents/profile-2-lg.jpg', name: 'Lorena michelle Martinez', email: 'leonardojacob690115@gmail.com', phone: '961 367 7893', page: null },
        { imageUrl: 'public/images/pages/agents/profile-2.jpg', imageUrlLg: 'public/images/pages/agents/profile-2-lg.jpg', name: 'Lorena  Muñoz Robledo', email: 'lorena.viajare@gmail,com', phone: '33 2574 9211', page: 'https://www.facebook.com/Lorenamunozagenteviajare' },
        { imageUrl: 'public/images/pages/agents/profile-2.jpg', imageUrlLg: 'public/images/pages/agents/profile-2-lg.jpg', name: 'Ma Del Carmen Arambula Islas', email: 'carmita71@live.com.mx', phone: '33 3956 4778', page: null },
        { imageUrl: 'public/images/pages/agents/profile-2.jpg', imageUrlLg: 'public/images/pages/agents/profile-2-lg.jpg', name: 'Maria De Jesus Zamora  Quiroz', email: 'chuyaquiroz@gmail.com', phone: '33 2566 9015', page: 'https://www.facebook.com/profile.php?id=61552378558844' },
        { imageUrl: 'public/images/pages/agents/profile-2.jpg', imageUrlLg: 'public/images/pages/agents/profile-2-lg.jpg', name: 'Martha Leticia Gonzalez Sanchez', email: 'leticiag0187@gmail.com', phone: '33 1014 4665', page: 'https://www.facebook.com/MarthayArmandoAgentesViajare' },
        { imageUrl: 'public/images/pages/agents/profile-2.jpg', imageUrlLg: 'public/images/pages/agents/profile-2-lg.jpg', name: 'Nancy Ivonne Gutierrez Herrera', email: 'alexivonne.viajare@gmail.com', phone: '3321064929', page: null },
        { imageUrl: 'public/images/pages/agents/profile-1.jpg', imageUrlLg: 'public/images/pages/agents/profile-1-lg.jpg', name: 'Noe Barba Mercado', email: 'noe.barba@gmail.com', phone: '33 1172 5279', page: null },
        { imageUrl: 'public/images/pages/agents/profile-2.jpg', imageUrlLg: 'public/images/pages/agents/profile-2-lg.jpg', name: 'Patricia Cecilia Meza Ibarra', email: 'patycecilia1@gmail.com', phone: '33 3676 1265', page: null },
        { imageUrl: 'public/images/pages/agents/profile-1.jpg', imageUrlLg: 'public/images/pages/agents/profile-1-lg.jpg', name: 'Ramon Vazquez  Garcia', email: 'ramonvazquez.viajare@hotmail.com', phone: '33 1243 6174', page: 'https://www.facebook.com/profile.php?id=100092227234649' },
        { imageUrl: 'public/images/pages/agents/profile-2.jpg', imageUrlLg: 'public/images/pages/agents/profile-2-lg.jpg', name: 'Sofia  Alonso Hidalgo', email: 'sofialonsohidalgo@gmail.com', phone: '33 1893 0667', page: null },
        { imageUrl: 'public/images/pages/agents/profile-2.jpg', imageUrlLg: 'public/images/pages/agents/profile-2-lg.jpg', name: 'Ana Aurora  Hernandez  Montes', email: 'ahernandezm43@gmail.com', phone: '33 1476 3822', page: null },
        { imageUrl: 'public/images/pages/agents/profile-2.jpg', imageUrlLg: 'public/images/pages/agents/profile-2-lg.jpg', name: 'Aurora De Jesus Chavez  Ramirez', email: 'marcodelli@hotmail.com', phone: '33 1713 4544', page: null },
        { imageUrl: 'public/images/pages/agents/profile-2.jpg', imageUrlLg: 'public/images/pages/agents/profile-2-lg.jpg', name: 'Carmen Guadalupe Pila  Bautista', email: 'carmenpilabautista@gmail.com', phone: '33 3489 9405', page: 'https://www.facebook.com/CarmenPilaAgenteViajare' },
        { imageUrl: 'public/images/pages/agents/profile-2.jpg', imageUrlLg: 'public/images/pages/agents/profile-2-lg.jpg', name: 'Claudia Fernanda Alonso Vergara', email: 'ferclauh_@hotmail.com', phone: '33 1774 9014', page: null },
        { imageUrl: 'public/images/pages/agents/profile-1.jpg', imageUrlLg: 'public/images/pages/agents/profile-1-lg.jpg', name: 'Diego Gabriel Villanueva Diaz', email: 'jackiedg08@gmail.com', phone: '33 2137 6828', page: 'https://www.facebook.com/search/top?q=diego%20villanueva%20agente%20viajare' },
        { imageUrl: 'public/images/pages/agents/profile-2.jpg', imageUrlLg: 'public/images/pages/agents/profile-2-lg.jpg', name: 'Elizabeth Perez Gutiérrez', email: 'eli-glamour@hotmail.com', phone: '33 2091 8291', page: null },
        { imageUrl: 'public/images/pages/agents/profile-1.jpg', imageUrlLg: 'public/images/pages/agents/profile-1-lg.jpg', name: 'Francisco Javier Bocardo Perez', email: 'fcobocardo@gmail.com', phone: '33 1354 2724', page: 'https://www.facebook.com/ViajareBocardoMorgan' },
        { imageUrl: 'public/images/pages/agents/profile-1.jpg', imageUrlLg: 'public/images/pages/agents/profile-1-lg.jpg', name: 'Gerardo Castro Flores', email: 'gerardo.viajare@gmail.com', phone: '33 3447 3739', page: 'https://www.facebook.com/profile.php?id=61554138950652' },
        { imageUrl: 'public/images/pages/agents/profile-1.jpg', imageUrlLg: 'public/images/pages/agents/profile-1-lg.jpg', name: 'Jared Ernesto Montes  Rubio', email: 'ernesto.montes.r98@gmail.com', phone: '33 1572 9915', page: 'https://www.facebook.com/profile.php?id=61553702320132' },
        { imageUrl: 'public/images/pages/agents/profile-1.jpg', imageUrlLg: 'public/images/pages/agents/profile-1-lg.jpg', name: 'Jesus Salvador Mendoza Hernandez', email: 'creativeworld020621@gmail.com', phone: '33 3451 7577', page: null },
        { imageUrl: 'public/images/pages/agents/profile-1.jpg', imageUrlLg: 'public/images/pages/agents/profile-1-lg.jpg', name: 'Jose Luis Sanchez Bastida', email: 'jlsanchez.viajare@gmail.com', phone: '55 4107 2362', page: 'https://www.facebook.com/profile.php?id=61553924165564' },
        { imageUrl: 'public/images/pages/agents/profile-2.jpg', imageUrlLg: 'public/images/pages/agents/profile-2-lg.jpg', name: 'Karina Araceli Castillo Vazquez', email: 'anirak.ollitsac@gmail.com', phone: '33 1445 0653', page: 'https://www.facebook.com/KarinaCastilloAgenteViajare' },
        { imageUrl: 'public/images/pages/agents/profile-2.jpg', imageUrlLg: 'public/images/pages/agents/profile-2-lg.jpg', name: 'Karina Solano  Macias', email: 'karina.viajare@gmail.com', phone: '33 1776 9845', page: 'https://www.facebook.com/profile.php?id=61551626734698' },
        { imageUrl: 'public/images/pages/agents/profile-2.jpg', imageUrlLg: 'public/images/pages/agents/profile-2-lg.jpg', name: 'Liliana Aguilera Aguilar', email: 'lilianaaguilera_@hotmail.com', phone: '33 1094 1492', page: null },
        { imageUrl: 'public/images/pages/agents/profile-2.jpg', imageUrlLg: 'public/images/pages/agents/profile-2-lg.jpg', name: 'Maria Guadalupe Lopez  Haro', email: 'lupita68699@gmail.com', phone: '33 1520 2225', page: null },
        { imageUrl: 'public/images/pages/agents/profile-2.jpg', imageUrlLg: 'public/images/pages/agents/profile-2-lg.jpg', name: 'Marina Soledad Gonzalez de la Torre', email: 'acuario.marina@icloud.com', phone: '33 1992 5172', page: 'https://www.facebook.com/MaryAgenteViajare' },
        { imageUrl: 'public/images/pages/agents/profile-2.jpg', imageUrlLg: 'public/images/pages/agents/profile-2-lg.jpg', name: 'Sandra Leticia Barajas Ureïña', email: 'sandrabu@outlook.com', phone: '33 3821 9762', page: 'https://www.facebook.com/profile.php?id=61557518213327' },
        { imageUrl: 'public/images/pages/agents/profile-2.jpg', imageUrlLg: 'public/images/pages/agents/profile-2-lg.jpg', name: 'Amalda Maria Chavez Almada', email: 'amaldaesfer@gmail.com', phone: '61 4189 6392', page: null },
        { imageUrl: 'public/images/pages/agents/profile-2.jpg', imageUrlLg: 'public/images/pages/agents/profile-2-lg.jpg', name: 'Claudia Gabriela Nisino  González', email: 'claudianisino_gabriela@hotmail.com', phone: '3313868316', page: null },
        { imageUrl: 'public/images/pages/agents/profile-2.jpg', imageUrlLg: 'public/images/pages/agents/profile-2-lg.jpg', name: 'Elda Maria Lopez Crespo', email: 'eldaitzae@icloud.com', phone: '47 3148 8592', page: null },
        { imageUrl: 'public/images/pages/agents/profile-2.jpg', imageUrlLg: 'public/images/pages/agents/profile-2-lg.jpg', name: 'Elizabeth Orozco  Sánchez', email: 'elizabethorosan@gmail.com', phone: '33 1287 2306', page: 'https://www.facebook.com/profile.php?id=61556074277363' },
        { imageUrl: 'public/images/pages/agents/profile-2.jpg', imageUrlLg: 'public/images/pages/agents/profile-2-lg.jpg', name: 'Elizabeth Maria Del Refugio Carrillo Valadez', email: 'sisicv@hotmail.com', phone: '33 2506 4119', page: null },
        { imageUrl: 'public/images/pages/agents/profile-1.jpg', imageUrlLg: 'public/images/pages/agents/profile-1-lg.jpg', name: 'Fernando Aguilar Arroyo', email: 'faguilar.viajare@gmail.com', phone: '3331461001', page: null },
        { imageUrl: 'public/images/pages/agents/profile-1.jpg', imageUrlLg: 'public/images/pages/agents/profile-1-lg.jpg', name: 'Jaime Arellano Arellano', email: 'james.a-777@hotmail.com', phone: '3322925594', page: 'https://www.facebook.com/profile.php?id=61557030104191&locale=es_LA' },
        { imageUrl: 'public/images/pages/agents/profile-1.jpg', imageUrlLg: 'public/images/pages/agents/profile-1-lg.jpg', name: 'J. Jesus Gonzalez', email: 'Sunset6055@hotmail.com ', phone: '61 9664 9699', page: 'https://www.facebook.com/profile.php?id=100064114240588' },
        { imageUrl: 'public/images/pages/agents/profile-1.jpg', imageUrlLg: 'public/images/pages/agents/profile-1-lg.jpg', name: 'Jorge Alberto Flores Maldonado', email: 'jofloma21@gmail.com', phone: '33 1335 4926', page: null },
        { imageUrl: 'public/images/pages/agents/profile-2.jpg', imageUrlLg: 'public/images/pages/agents/profile-2-lg.jpg', name: 'Josefina Mendoza  Briones', email: 'pinamendozab@hotmail.com', phone: '33 1194 0639', page: null },
        { imageUrl: 'public/images/pages/agents/profile-1.jpg', imageUrlLg: 'public/images/pages/agents/profile-1-lg.jpg', name: 'Juan Carlos Balleza  Valdez', email: 'Juankisballeza@gmail.com', phone: '33 1325 1083', page: 'https://www.facebook.com/CarlosBallezaAgenteViajare/' },
        { imageUrl: 'public/images/pages/agents/profile-1.jpg', imageUrlLg: 'public/images/pages/agents/profile-1-lg.jpg', name: 'Juan Carlos  Torres   González', email: 'juancarlo911@hotmail.com', phone: '33 1264 8289', page: 'https://www.facebook.com/profile.php?id=100067026291938 ' },
        { imageUrl: 'public/images/pages/agents/profile-1.jpg', imageUrlLg: 'public/images/pages/agents/profile-1-lg.jpg', name: 'Luis Fernando Briseño  Macias', email: 'ventas@losluises.com.mx', phone: '3319536965', page: '' },
        { imageUrl: 'public/images/pages/agents/profile-2.jpg', imageUrlLg: 'public/images/pages/agents/profile-2-lg.jpg', name: 'Maria De La Torre', email: 'mary.delatorre16@hotmail.com', phone: '33 3201 4199', page: null },
        { imageUrl: 'public/images/pages/agents/profile-2.jpg', imageUrlLg: 'public/images/pages/agents/profile-2-lg.jpg', name: 'Maria Del Pilar Hernandez Gomez', email: 'pily.viajare@gmail.com', phone: '33 3143 2126', page: 'https://www.facebook.com/profile.php?id=61558408777765' },
        { imageUrl: 'public/images/pages/agents/profile-1.jpg', imageUrlLg: 'public/images/pages/agents/profile-1-lg.jpg', name: 'Miguel Ïñngel  Garcïña Alvarez', email: 'ltmiguelgarcia@gmail.com', phone: '33 3572 0879', page: 'https://www.facebook.com/profile.php?id=61557919105722' },
        { imageUrl: 'public/images/pages/agents/profile-1.jpg', imageUrlLg: 'public/images/pages/agents/profile-1-lg.jpg', name: 'Pedro Arellano Loza', email: 'pedro.arellano.loza@gmail.com', phone: '33 2506 2901', page: null },
        { imageUrl: 'public/images/pages/agents/profile-2.jpg', imageUrlLg: 'public/images/pages/agents/profile-2-lg.jpg', name: 'Silvia Laguna Ventura', email: 'Silvia_1811@hotmail.com', phone: '33 3476 7176', page: 'https://www.facebook.com/profile.php?id=61557953572974' },
        { imageUrl: 'public/images/pages/agents/profile-2.jpg', imageUrlLg: 'public/images/pages/agents/profile-2-lg.jpg', name: 'Tania Elizabeth Garcia Valdivia', email: 'porelmundoviajare@gmail.com', phone: '33 2401 2238', page: null },
        { imageUrl: 'public/images/pages/agents/profile-1.jpg', imageUrlLg: 'public/images/pages/agents/profile-1-lg.jpg', name: 'Rigoberto Gonzalez Gutierrez', email: 'chachogon@gmail.com', phone: '37 8708 9822', page: 'https://www.facebook.com/soyviajarelosaltos' },
        { imageUrl: 'public/images/pages/agents/profile-1.jpg', imageUrlLg: 'public/images/pages/agents/profile-1-lg.jpg', name: 'Jose Luis Arce Lepe', email: 'arce.viajare@gmail.com', phone: '34 8102 9110', page: 'https://www.facebook.com/profile.php?id=61557402555124' },
        { imageUrl: 'public/images/pages/agents/profile-1.jpg', imageUrlLg: 'public/images/pages/agents/profile-1-lg.jpg', name: 'Abraham Hernandez Chavez', email: 'eibram.hch@gmail.com', phone: '33 1568 8084', page: 'https://www.facebook.com/profile.php?id=61559402242263' },
        { imageUrl: 'public/images/pages/agents/profile-1.jpg', imageUrlLg: 'public/images/pages/agents/profile-1-lg.jpg', name: 'Alfonso Yared Castellanos Lopez', email: 'yared_castell@hotmail.com', phone: '378 109 8001', page: null },
        { imageUrl: 'public/images/pages/agents/profile-1.jpg', imageUrlLg: 'public/images/pages/agents/profile-1-lg.jpg', name: 'Jose Antonio Rodriguez', email: 'rodner.82@gmail.com', phone: '476 171 6103', page: null },
        { imageUrl: 'public/images/pages/agents/profile-1.jpg', imageUrlLg: 'public/images/pages/agents/profile-1-lg.jpg', name: 'Luis Alberto Flores Anguiano', email: 'beto.flores0849@gmail.com', phone: '477 153 1170', page: 'https://www.facebook.com/LuisFloresAgenteViajare' },
        { imageUrl: 'public/images/pages/agents/profile-2.jpg', imageUrlLg: 'public/images/pages/agents/profile-2-lg.jpg', name: 'Mercedes  Solano Correa', email: null, phone: null, page: 'https://www.facebook.com/profile.php?id=100070978050249' },
        { imageUrl: 'public/images/pages/agents/profile-2.jpg', imageUrlLg: 'public/images/pages/agents/profile-2-lg.jpg', name: 'Miriam Mercedes Franco Ponce', email: 'miriamfranco537@gmail.com', phone: '442 471 9745', page: 'https://www.facebook.com/MiriamFrancoAgenteViajare/' },
        { imageUrl: 'public/images/pages/agents/profile-1.jpg', imageUrlLg: 'public/images/pages/agents/profile-1-lg.jpg', name: 'Nimroth Barrera Segoviano', email: 'barreranimroth@gmail.com', phone: '473 108 4810', page: 'https://www.facebook.com/profile.php?id=100070032863349' },
        { imageUrl: 'public/images/pages/agents/profile-1.jpg', imageUrlLg: 'public/images/pages/agents/profile-1-lg.jpg', name: 'Ricardo Gasca Liceaga', email: 'r_gasca@hotmail.com', phone: '477 304 8872', page: 'https://www.facebook.com/profile.php?id=61553905896639' },
        { imageUrl: 'public/images/pages/agents/profile-1.jpg', imageUrlLg: 'public/images/pages/agents/profile-1-lg.jpg', name: 'Rigoberto Gómez  Guzmán', email: 'rigomguz23@gmail.com', phone: '476 101 0696', page: null },
        { imageUrl: 'public/images/pages/agents/profile-1.jpg', imageUrlLg: 'public/images/pages/agents/profile-1-lg.jpg', name: 'Uriel Marco Polo  Caballero Reyes', email: 'm_arco99@hotmail.com', phone: '722 245 7391', page: null },
        { imageUrl: 'public/images/pages/agents/profile-2.jpg', imageUrlLg: 'public/images/pages/agents/profile-2-lg.jpg', name: 'Yesenia Gutierrez Morales', email: 'yessgtz643@gmail.com', phone: ' 472 155 42 89', page: 'https://www.facebook.com/profile.php?id=61556233840634' },
        { imageUrl: 'public/images/pages/agents/profile-2.jpg', imageUrlLg: 'public/images/pages/agents/profile-2-lg.jpg', name: 'Yesenia Macedo  Macedo', email: 'yesimacedo2@gmail.com', phone: '378 185 8840', page: 'https://www.facebook.com/profile.php?id=100070499454725' },
        { imageUrl: 'public/images/pages/agents/profile-2.jpg', imageUrlLg: 'public/images/pages/agents/profile-2-lg.jpg', name: 'Alicia Mayeli Macias Barajas', email: null, phone: '33 2196 2748', page: null },
        { imageUrl: 'public/images/pages/agents/profile-2.jpg', imageUrlLg: 'public/images/pages/agents/profile-2-lg.jpg', name: 'Claudia Lopez Gutierrez', email: null, phone: '473 120 7251', page: null },
        { imageUrl: 'public/images/pages/agents/profile-1.jpg', imageUrlLg: 'public/images/pages/agents/profile-1-lg.jpg', name: 'Uriel Marco Polo Caballero Reyes', email: null, phone: '722 245 7391', page: null },
        { imageUrl: 'public/images/pages/agents/profile-2.jpg', imageUrlLg: 'public/images/pages/agents/profile-2-lg.jpg', name: 'Nancy Elizabeth Juarez Garcia', email: 'nellyjuarez.viajare@icloud.com', phone: ' 477135 26 75', page: null },
      ],

      modal: {
        imageUrl: null,
        imageUrlLg: null,
        name: null,
        phone: null,
        email: null,
        page: null,
      },

      forRating: {
        stars: null,
        name: null,
        comment: null,
      },

      starsExample: 4,
    }
  },

  methods: {
    openModal(agent) {
      this.modal = agent;
      this.$refs['modal-agent'].show();
    },

    onSubmitRate(event) {
      event.preventDefault();
      this.$refs['modal-rate'].hide();
    }
  },

  mounted() {
    // this.$refs['modal-agent'].show();
  }
}
</script>
