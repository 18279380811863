<template lang="html">
  <div id="travelover-page">

    <section class="placed-backg banner-section">
    </section>

    <section class="container agent-section">
      <div class="box-top">
        <h2 class="mb-3 title-s1 c-primary">AGENTE AUTORIZADO</h2>

        <p>
          <a class="btn btn-s2 outline-csecondary b-radius" href="#">¿QUÉ ES UN SHERPA?</a>
          <a class="btn btn-s2 outline-csecondary b-radius" href="#">¿QUÉ OFRECEMOS?</a>
          <a class="btn btn-s2 outline-csecondary b-radius" href="#">¿QUIÉNES SOMOS?</a>
          <a class="btn btn-s2 outline-csecondary b-radius" href="#">TIPOS DE SHERPAS</a>
        </p>
      </div>

      <div class="box-carousel">
        <h5 class="subtitle">¡ÚNETE <span>AHORA!</span></h5>

        <swiper class="swiper" :options="agentsOptions">
          <swiper-slide v-for="(b, btInx) in 3" :key="'btInx-'+btInx">
            <div class="box-image">
              <img src="public/images/pages/travelover/banner-2-1.jpg">
            </div>
          </swiper-slide>

          <div class="swiper-button-prev" slot="button-prev"></div>
          <div class="swiper-button-next" slot="button-next"></div>
        </swiper>

        <p class="text-center mt-3 txt-c-primary">
          CONSULTA NUESTROS <a class="f-w-700 txt-c-primary" href="#">TÉRMINOS Y CONDICIONES</a>
        </p>
      </div>
    </section>

    <section class="container benefits-section">
      <div class="box-title">
        <h2 class="title-s1">BENEFICIOS</h2>
      </div>

      <div class="box-earnings">
        <h4><span class="f-w-700">GANANCIAS DE MÁS DE</span><br />$20,000 PESOS</h4>
      </div>

      <div class="box-information">
        <div class="col-lg-7 placed-backg  col-image"></div>

        <div class="col-lg-5 col-text">
          <div class="box">
            <h4 class="title">
              TU AGENCIA DE VIAJES DE MANERA "ONLINE"
            </h4>

            <div class="info">
              <p>
                <strong>Nuestro modelo de negocio es ideal para ti que te gusta todo lo relacionado con ventas por internet</strong>
                y tienes un nicho de mercado que viaja.<br />
                Iníciate con todas las herramientas necesarias para desarrollar tu agencia de viajes virtual. Un negocio de fácil operación y que puedes realizar en tu tiempo libre desde la comodidad de tu casa.
              </p>
            </div>

            <p class="mt-3 mt-sm-4 text-center text-lg-left">
              <router-link class="btn btn-s2 bg-white" to="">ME INTERESA AFILIARME</router-link>
            </p>
          </div>
        </div>
      </div>
    </section>

    <section class="container how-works-section">
      <div class="box">
        <h2 class="mb-4 main-title">¿CÓMO FUNCIONA?</h2>

        <p class="text-center">
          <img class="img-fluid" src="public/images/pages/travelover/how-works.svg">
        </p>

        <div class="row box-academy">
          <div class="col-lg-5 col-logo">
            <img src="public/images/pages/travelover/viajare-academia.svg" alt="">
          </div>

          <div class="col-lg-7 col-info">
            <h4 class="text-1">
              CON ACADEMIA VIAJARE DESARROLLARÁS LAS <strong>HERRAMIENTAS</strong> NECESARIAS PARA COMENZAR <strong>TU</strong>
            </h4>
            <h3 class="text-2">CAMINO AL ÉXITO.</h3>

            <h5 class="mt-4 subtitle">¿ESTÁS LISTO?</h5>

            <p class="mt-3">
              <a class="btn btn-s1 bg-c-primary" href="#">MÁS INFORMACIÓN</a>
            </p>

            <div class="box-opts">
              <div class="box-icon">
                <img src="public/images/pages/travelover/ic-graduation.png">
              </div>

              <h5 class="mt-4 subtitle"><i class="fa-solid fa-check"></i> <span>Preparación</span></h5>
              <ul>
                <li>Capacitación constante</li>
                <li>Soporte en la Venta (coordinadores de ventas)</li>
                <li>Soporte procesos administrativos</li>
                <li>Soporte procesos</li>
                <li>Apoyo para emergencias o dudas urgentes y acompañamiento (Atención por WhatsApp, vía telefónica, etc.)</li>
              </ul>
            </div>

            <div class="box-opts">
              <div class="box-icon">
                <img src="public/images/pages/travelover/ic-marketing.png">
              </div>

              <h5 class="mt-4 subtitle"><i class="fa-solid fa-check"></i> <span>Marketing</span></h5>
              <ul>
                <li>Apoyo en marketing y apertura de redes sociales (Facebook e Instagram)</li>
                <li>Uso de marca</li>
                <li>Material de ventas</li>
              </ul>
            </div>

            <div class="box-opts">
              <div class="box-icon">
                <img src="public/images/pages/travelover/ic-platforms.png">
              </div>

              <h5 class="mt-4 subtitle"><i class="fa-solid fa-check"></i> <span>Plataformas</span></h5>
              <ul>
                <li>Oficina virtual</li>
                <li>Plataforma de proveedores</li>
              </ul>
            </div>

            <div class="box-opts">
              <div class="box-icon">
                <img src="public/images/pages/travelover/ic-commission.png">
              </div>

              <h5 class="mt-4 subtitle"><i class="fa-solid fa-check"></i> <span>Comisiones & Descuentos</span></h5>
              <ul>
                <li>Viajes de familiarización</li>
                <li>Networking</li>
                <li>Precios especiales para viajes personales</li>
                <li>Libertad geográfica</li>
                <li>Libertad de horarios</li>
              </ul>
            </div>
          </div>
        </div>

        <div class="box-ready">
          <h5 class="subtitle">¿AÚN NO ESTÁS LISTO?</h5>
          <h6>CON VIAJARE TENDRÁS UN<br />MUNDO DE OPORTUNIDADES.</h6>

          <p>
            <a class="btn btn-s2 bg-white b-radius">¡ESTAMOS PARA AYUDARTE!</a>
          </p>
        </div>
      </div>
    </section>

    <section class="container about-faqs-section">
      <h2 class="title-s1 c-primary">SÉ TU PROPIA JEFA Y LOGRA INDEPENDENCIA ECONÓMICA</h2>

      <div class="row">
        <div class="col-lg-6 col-info">
          <div class="box-intro">
            <div class="box-title">
              <h4>
                CONOCE CÓMO <strong>VIAJARE HA CAMBIADO LA VIDA DE NUESTROS DISTRIBUIDORES</strong>
              </h4>
            </div>

            <div class="d-block mb-3">
              <img class="img-fluid" src="public/images/pages/travelover/icons-1.svg">
            </div>

            <div class="d-block">
              <p class="p-check">
                <i class="fa-solid fa-check icon"></i> <strong>TE CAPACITAMOS</strong> A TRAVÉS DE VIAJARE ACADEMY
              </p>
              <p class="p-check">
                <i class="fa-solid fa-check icon"></i> <strong>SERÁS TU PROPÍA JEFA</strong> CON TUS PROPIOS HORARIOS DE TRABAJO
              </p>
              <p class="p-check">
                <i class="fa-solid fa-check icon"></i> ¡EL MARGEN DE <strong>TUS GANANCIAS LO PONES TÚ MISMA!</strong>
              </p>
            </div>
          </div>

          <div class="box-faqs">
            <h5 class="mb-1 subtitle">PREGUNTAS FRECUENTES</h5>
            <p class="txt-c-secondary">Aquí las respuestas a las preguntas más comunes sobre Viajare Afiliados</p>

            <div class="faqs">
              <b-card no-body v-for="(qa, qaInx) in faqs" :key="'qaInx-'+qaInx">
                <b-card-header header-tag="header" role="tab">
                  <a class="btn-question" v-b-toggle="'accordion-'+qaInx">{{ qa.q }}</a>
                </b-card-header>
                <b-collapse :id="'accordion-'+qaInx" accordion="my-accordion" role="tabpanel">
                  <b-card-body>
                    <p>
                      Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.<br />Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
                    </p>
                  </b-card-body>
                </b-collapse>
              </b-card>
            </div>
          </div>
        </div>

        <div class="col-lg-6 col-videos">
          <div class="box-video">
            <img src="public/images/pages/travelover/video-1.jpg">
          </div>

          <div class="box-video">
            <img src="public/images/pages/travelover/video-2.jpg">
          </div>

          <div class="box-video">
            <img src="public/images/pages/travelover/video-3.jpg">
          </div>
        </div>
      </div>
    </section>

    <section class="calculator-section">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-lg-10 col-content px-lg-0">
            <h4 class="text-center title-1">CALCULA CUÁNTO PUEDES GANAR</h4>
            <h5 class="text-center subtitle">COMO AGENTE AUTORIZADO “TRAVELOVER”</h5>

            <h4 class="mt-4 text-center title-1">SI YO VENDO:</h4>

            <div class="box-calculator-form">
              <b-form @submit="onSubmitCalculator">
                <b-form-group>
                  <b-form-input
                    v-model="formCalculator.amount"
                    type="number"
                    placeholder="INGRESA UN MONTO (VENTAS)"
                    required
                  ></b-form-input>
                </b-form-group>

                <b-button type="submit" class="btn btn-s1 bg-c-secondary">CALCULAR MIS INGRESOS</b-button>
              </b-form>
            </div>

            <div class="box-guarantee">
              <h4 class="text-center title-1">MIS GANANCIAS SERÍAN DE:</h4>

              <div class="guarantee">
                <div class="text-right txt">$</div>
                <div class="total">1,560.00</div>
                <div class="text-left txt">MXN</div>
              </div>

              <h6 class="sub">¡QUIERO SER UN TRAVELOVER!</h6>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="container contact-section">
      <h2 class="title-s1 c-primary">CONTÁCTANOS</h2>

      <div class="box-form">
        <b-form @submit="onSubmitContact">
          <b-form-group>
            <b-form-input
              v-model="formContact.text"
              type="text"
              placeholder="NOMBRE Y APELLIDO"
              required
            ></b-form-input>
          </b-form-group>

          <b-form-group>
            <b-form-input
              v-model="formContact.email"
              type="email"
              placeholder="CORREO ELECTRÓNICO"
              required
            ></b-form-input>
          </b-form-group>

          <b-form-group>
            <b-form-input
              v-model="formContact.phone"
              type="text"
              placeholder="TELÉFONO"
              minwidth="10"
              maxwidth="10"
              required
            ></b-form-input>
          </b-form-group>

          <b-form-group>
            <b-form-textarea
              v-model="formContact.msg"
              placeholder="ASUNTO"
              rows="4"
              max-rows="4"
            ></b-form-textarea>
          </b-form-group>

          <div class="row pt-1 pt-lg-3">
            <div class="col-lg-9 col-text">
              <p>
                RELLENA EL FORMULARIO CON TUS DATOS, DÍNOS LO QUE NECESITAS
                Y TE RESPONDEREMOS LO ANTES POSIBLE. OLVÍDATE DE
                TUS INQUIETUDES Y COMIENZA ESTA TRAVESÍA.
              </p>
            </div>

            <div class="col-lg-3 col-button mt-3 mt-sm-2 mt-lg-0">
              <b-button type="submit" class="btn btn-s1 bg-c-primary">ENVIAR INFO</b-button>
            </div>
          </div>
        </b-form>
      </div>
    </section>

  </div>
</template>

<script>
export default {
  data() {
    return {
      faqs: [
        { q: '¿Cómo puedo afiliarme? ¿Qué requisitos solicitan?' },
        { q: '¿Qué beneficios tengo al ser socio de Viajare?' },
        { q: '¿Cuál es el costo de afiliación?' },
        { q: '¿Qué tipo de pagos tienen para afiliarme?' },
        { q: '¿Cuál es el porcentaje de ganancia?' },
        { q: '¿Qué documentos necesito para tener la credencial?' },
      ],

      formCalculator: {
        amount: null,
      },

      formContact: {
        name: null,
        email: null,
        phone: null,
        msg: null,
      },

      // == Carousel options ==
      agentsOptions: {
        loop: true,

        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        }
      }
      // == ==
    }
  },

  methods: {
    onSubmitCalculator(event) {
      event.preventDefault();
    },

    onSubmitContact(event) {
      event.preventDefault();
    },
  },
}
</script>
