<template lang="html">
  <div id="payments-page">

    <section class="placed-backg banner-section">
      <img src="public/images/pages/payments/banner-1-m.jpg">
    </section>

    <section class="container payment-options-section">
      <div class="box-intro">
        <p class="text-center">
          <img class="title-img" src="public/images/pages/payments/opciones-pago.svg">
        </p>
        <h5 class="subtitle">Conoce los distintos métodos y facilidades de pago para tu próximo viaje</h5>
      </div>

      <div class="box-list">
        <p class="p-list">
          <i class="fa-sharp fa-solid fa-check"></i> Aparta y paga poco a poco.
        </p>
        <p class="p-list">
          <i class="fa-sharp fa-solid fa-check"></i> Pago completo al reservar.
        </p>
        <p class="p-list">
          <i class="fa-sharp fa-solid fa-check"></i> Aparta y líquida con tarjeta de crédito o débito.
        </p>
      </div>

      <div class="row box-options">
        <div class="col-md-4 col-opt">
          <div class="t-150 circle">
            <span>EFECTIVO</span>
          </div>
        </div>

        <div class="col-md-4 col-opt">
          <div class="t-150 circle">
            <span class="t-2">TRANSFERENCIA</span>
          </div>
        </div>

        <div class="col-md-4 col-opt">
          <div class="t-150 circle">
            <span class="t-3">MSI</span>
          </div>
        </div>
      </div>

      <div class="box-logos">
        <img src="public/images/pages/payments/visa-mastercard-paypal.svg">
      </div>
    </section>

    <section class="banks-info-section">
      <div class="container">
        <h4 class="text-1">
          Viajare Grupo Class Tours, SAPI de CV<br />
          RFC: VGC121025AR0
        </h4>

        <h5 class="text-2">Elige el banco de tu preferencia:</h5>

        <div class="box-banks">
          <div class="row justify-content-center">
            <div class="col-lg-4 col-logo">
              <div class="box">
                <div class="inside">
                  <img src="public/images/pages/payments/bbva.svg">
                </div>
              </div>
            </div>

            <div class="col-lg-6 col-text">
              <div class="box">
                <p>
                  BBVA BANCOMER<br />
                  CTA: 0100137162<br />
                  CLABE INT: 012320001001371625
                </p>
              </div>
            </div>
          </div>

          <div class="row justify-content-center">
            <div class="col-lg-4 col-logo">
              <div class="box">
                <div class="inside">
                  <img src="public/images/pages/payments/banorte.svg">
                </div>
              </div>
            </div>

            <div class="col-lg-6 col-text">
              <div class="box">
                <p>
                  BANORTE<br />
                  CTA: 0848609001<br />
                  CLABE INT: 072320008486090014
                </p>
              </div>
            </div>
          </div>

          <div class="row justify-content-center">
            <div class="col-lg-4 col-logo">
              <div class="box">
                <div class="inside">
                  <img src="public/images/pages/payments/santander.svg">
                </div>
              </div>
            </div>

            <div class="col-lg-6 col-text">
              <div class="box">
                <p>
                  SANTANDER<br />
                  CTA: 65508790986<br />
                  CLABE INT: 014320655087909863
                </p>
              </div>
            </div>
          </div>
        </div>

        <h6 class="pt-2 text-center f-w-600"><span class="mr-1 f-w-700 h4">*OJO:</span> Únicas cuentas oficiales, cualquier depósito que no sea realizado estas, Viajare no se hará responsable.</h6>
      </div>
    </section>

    <div class="bottom-banner-section">
      <img class="img-desktop" src="public/images/pages/payments/banner-2-1.jpg">
      <img class="img-mobile" src="public/images/pages/payments/banner-2-1-m.jpg">
    </div>

  </div>
</template>

<script>
export default {
}
</script>
