<template lang="html">
  <div id="blog-page">

    <section class="placed-backg header-banners-section-s1" v-bind:style="{ backgroundImage: 'url(public/images/pages/blog/banner-1.jpg)' }">
      <div class="container">
        <div class="row">
          <div class="col-12">
            <div class="box">
              <h1>EL BLOG</h1>

              <p class="p-lg">
                Entérate en todo momento de las noticias más relevantes del mundo del turismo.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="container articles-section">
      <div class="container">
        <div class="row box-articles">
          <div class="col-lg-6 col-article" v-for="(b, bInx) in blog" :key="'bInx-'+bInx">
            <router-link class="t-250 box-article-s1" to="/blog/1">
              <div class="placed-backg t-250 box-image" v-bind:style="{ backgroundImage: 'url('+b.imgUrl+')' }"></div>

              <div class="box-info">
                <h5 class="t-250 title">{{ b.title }}</h5>

                <div class="descr">
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
                  </p>
                  <p>
                    Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
                  </p>
                </div>
              </div>
            </router-link>
          </div>
        </div>
      </div>
    </section>

  </div>
</template>

<script>
export default {
  data() {
    return {
      blog: [
        { imgUrl: 'public/images/pages/blog/image-1.jpg', title: 'Podrás tramitar el pasaporte desde whatsapp anuncia SRE.' },
        { imgUrl: 'public/images/pages/blog/image-2.jpg', title: 'Los mejores lugares para visitar para este año nuevo.' },
        { imgUrl: 'public/images/pages/blog/image-3.jpg', title: '¿Qué hacer para evitar perder tu vuelo al hacer un transbordo?' },
        { imgUrl: 'public/images/pages/blog/image-4.jpg', title: 'Consejos de viajeros expertos para evitar el jetlag.' },
      ],
    }
  }
}
</script>
