<template lang="html">
  <div id="home-page">

    <section class="banner-section">
      <swiper class="swiper desktop" :options="bannersOptions">
        <swiper-slide v-for="(b, bhInx) in banners" :key="'bhInx-'+bhInx">
          <div class="placed-backg image" v-bind:style="{ backgroundImage: 'url('+b.imageUrl+')' }"></div>
        </swiper-slide>

        <div class="swiper-button-prev" slot="button-prev"></div>
        <div class="swiper-button-next" slot="button-next"></div>
      </swiper>

      <swiper class="swiper mobile" :options="bannersOptions">
        <swiper-slide v-for="(bm, bmhInx) in bannersMobile" :key="'bmhInx-'+bmhInx">
          <div class="placed-backg image" v-bind:style="{ backgroundImage: 'url('+bm.imageUrl+')' }">
            <img src="public/images/pages/home/banner-m.png">
          </div>
        </swiper-slide>

        <div class="swiper-button-prev" slot="button-prev"></div>
        <div class="swiper-button-next" slot="button-next"></div>
      </swiper>
    </section>

    <section class="container about-section">
      <div class="row">
        <div class="col-lg-6 col-about">
          <div class="box">
            <div class="inside">
              <p>
                <img class="logo" src="public/images/logo.svg">
              </p>

              <h5>
                Conoce <strong>TODOS nuestros productos descargando aquí el catálogo</strong> digital 2024
              </h5>

              <p>
                <a class="btn btn-s2 outline-cprimary" href="#">CATÁLOGO 2023</a>
              </p>
            </div>
          </div>
        </div>

        <div class="col-lg-6 col-contact">
          <div class="box-contact-1">
            <div class="inside">
              <p>
                <i class="fas fa-lock-alt lock-icon"></i>
              </p>
              <h5>
                Contamos con reconocimientos y alianzas conel Gobierno para hacer que tus compras sean
              </h5>
              <h4>100% seguras</h4>
            </div>
          </div>

          <div class="box-contact-2">
            <div class="col-sm col-logo">
              <img src="public/images/pages/home/SECTUR.svg">
            </div>

            <div class="col-sm col-text">
              <h6>Consulta nuestro registro oficial y actualizado en la Secretaría de Turismo (SECTUR).</h6>
              <h6 class="mt-2">Registro <strong class="f-w-600">04140390979</strong></h6>

              <p class="mt-3">
                <router-link class="btn btn-s2 outline-csecondary" to="">CONSULTAR</router-link>
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>

  </div>
</template>

<script>
export default {
  data() {
    return {
      banners: [
        { imageUrl: 'public/images/pages/home/banner-1.jpg' },
        { imageUrl: 'public/images/pages/home/banner-2.jpg' },
        { imageUrl: 'public/images/pages/home/banner-3.jpg' },
        { imageUrl: 'public/images/pages/home/banner-4.jpg' },
        { imageUrl: 'public/images/pages/home/banner-5.jpg' },
      ],
      bannersMobile: [
        { imageUrl: 'public/images/pages/home/banner-1-m.jpg' },
        { imageUrl: 'public/images/pages/home/banner-2-m.jpg' },
        { imageUrl: 'public/images/pages/home/banner-3-m.jpg' },
        { imageUrl: 'public/images/pages/home/banner-4-m.jpg' },
        { imageUrl: 'public/images/pages/home/banner-5-m.jpg' },
      ],

      // == Carousel options ==
      bannersOptions: {
        loop: true,
        speed: 700,
        effect: 'fade',

        autoplay: {
          delay: 4500,
          disableOnInteraction: false
        },

        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        }
      }
      // == ==
    }
  }
}
</script>
