<template lang="html">
  <header id="header">

    <section class="desktop-menu-section">
      <div class="container">
        <div class="row row-1">
          <div class="col-md-6 col-logo">
            <router-link to="/">
              <img src="public/images/logo-white.svg" alt="Viajare">
            </router-link>
          </div>

          <div class="col-md-6 col-networks">
            <p>
              <a class="t-150 btn-network" href="#">
                <i class="fa-brands fa-facebook-f"></i>
              </a>

              <a class="t-150 btn-network" href="#">
                <i class="fa-brands fa-x-twitter"></i>
              </a>

              <a class="t-150 btn-network" href="#">
                <i class="fa-brands fa-instagram"></i>
              </a>

              <a class="t-150 btn-network" href="#">
                <i class="fa-brands fa-youtube"></i>
              </a>

              <router-link class="btn btn-s1 bg-c-primary" to="">LOG IN</router-link>
            </p>
          </div>

          <div class="col-12 col-menu">
            <ul>
              <li><router-link to="/">INICIO</router-link></li>
              <li><router-link to="/quiero-viajar">QUIERO VIAJAR</router-link></li>
              <li><router-link to="/travelover">TRAVELOVER</router-link></li>
              <li><router-link to="/facilidades-de-pago">FACILIDADES DE PAGO</router-link></li>
              <li><router-link to="/agentes">AGENTES</router-link></li>
              <li><router-link to="/contacto">CONTACTO</router-link></li>
            </ul>
          </div>
        </div>
      </div>
    </section>

    <section class="mobile-menu-section">
      <b-navbar toggleable="lg" type="dark" variant="info">
        <b-navbar-brand to="/">
          <img src="public/images/logo-white.svg">
        </b-navbar-brand>

        <b-navbar-toggle target="nav-collapse">
          <i class="fa-sharp fa-regular fa-bars"></i>
        </b-navbar-toggle>

        <b-collapse id="nav-collapse" is-nav>
          <b-navbar-nav>
            <b-nav-item to="/">Inicio</b-nav-item>
            <b-nav-item to="/quiero-viajar">Quiero viajar</b-nav-item>
            <b-nav-item to="/travelover">Travelover</b-nav-item>
            <b-nav-item to="/facilidades-de-pago">Facilidades de pago</b-nav-item>
            <b-nav-item to="/agentes">Agentes</b-nav-item>
            <b-nav-item to="/contacto">Contacto</b-nav-item>
          </b-navbar-nav>
        </b-collapse>
      </b-navbar>
    </section>

  </header>
</template>

<script>
export default {
}
</script>
