<template lang="html">
  <footer id="footer">
		<div class="container main-container">
			<div class="row">
				<div class="col-12 col-logo">
					<router-link to="/">
						<img src="public/images/logo-blue.svg">
					</router-link>
				</div>

				<div class="col-12 col-newsletter">
					<h6 class="title">SUSCRÍBETE A NUESTRO NEWSLETTER</h6>

					<b-form inline @submit="onSubmit">
				    <b-form-input
				    	type="email"
				    	v-model="formNewsletter.email"
				      placeholder="Email"
							required
				    ></b-form-input>

						<b-button type="submit"><i class="fa-thin fa-chevron-right"></i></b-button>
				  </b-form>
				</div>

				<div class="col-12 col-networks">
					<a class="btn-network" href="#">
						<i class="fa-brands fa-facebook-f"></i>
					</a>

					<a class="btn-network" href="#">
						<i class="fa-brands fa-x-twitter"></i>
					</a>

					<a class="btn-network" href="#">
						<i class="fa-brands fa-instagram"></i>
					</a>

					<a class="btn-network" href="#">
						<i class="fa-brands fa-youtube"></i>
					</a>
				</div>

				<div class="col-12 col-links">
					<router-link to="/aviso-de-privacidad">AVISO DE PRIVACIDAD</router-link>
					<span class="sep">|</span>
					<router-link to="/terminos-y-condiciones">TÉRMINOS Y CONDICIONES</router-link>
					<span class="sep">|</span>
					<router-link to="/preguntas-frecuentes">PREGUNTAS FRECUENTES</router-link>
          <span class="sep">|</span>
          <span class="d-block d-sm-none"></span>
          <router-link to="/blog">BLOG</router-link>
				</div>
			</div>
		</div>

    <div class="box-copy">
      <p>
        <strong>VIAJARE GRUPO CLASS TOURS®</strong> | TODOS LOS DERECHOS RESERVADOS 2024
      </p>
    </div>
  </footer>
</template>

<script>
export default {
	data() {
		return {
			formNewsletter: {
				email: null
			}
		}
	},

	methods: {
    onSubmit(event) {
      event.preventDefault();
			console.log('xxxx');;
    }
	}
}
</script>
